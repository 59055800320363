import React from "react";
import CustomButton from "../Button.jsx/button";
import "./project.css";

const Project = ({ name, description, image, link }) => {
  return (
    <div className="projects__row">
      <div className="projects__row-img-cont">
        <img
          src={image}
          alt={`${name} screenshot`}
          className="projects__row-img"
          loading="lazy"
        />
      </div>
      <div className="projects__row-content">
        <h3 className="projects__row-content-title">{name}</h3>
        <p className="projects__row-content-desc">{description}</p>
        <CustomButton label="Case Study" link={link} dark size="md" />
      </div>
    </div>
  );
};

export default Project;
