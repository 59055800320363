import React from "react";
import "./hero.css";
import { Link } from "react-router-dom";
import CustomButton from "../Button.jsx/button";

const Hero = () => {
  return (
    <section className="home-hero" id="hero">
      <div className="home-hero__content">
        <h1 className="heading-primary">Hey, My name is Veronika Bowersock</h1>
        <div className="home-hero__info">
          <p className="text-primary">
            I love building beautiful and useful apps for web and mobile. I
            enjoy working with React, React Native and NodeJS.
          </p>
        </div>
        <div className="home-hero__cta">
          <CustomButton
            label="Projects"
            link="./#projects"
            dark={false}
            size="lg"
          />
        </div>
      </div>
      <div className="home-hero__socials">
        <div className="home-hero__social">
          <a
            href="https://www.linkedin.com/in/veronika-bowersock-a771b3bb/"
            className="home-hero__social-icon-link"
            target="_blank"
          >
            <img
              src="../../assets/png/linkedin-ico.png"
              alt="icon"
              className="home-hero__social-icon"
            />
          </a>
        </div>
        <div className="home-hero__social">
          <a
            href="https://github.com/verbowersock"
            target="_blank"
            className="home-hero__social-icon-link home-hero__social-icon-link--bd-none"
          >
            <img
              src="../../assets/png/github-ico.png"
              alt="icon"
              className="home-hero__social-icon"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
