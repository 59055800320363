import React from "react";
import "./skill.css";

const Skill = ({ skill }) => {
  const { name, img } = skill;
  return (
    <div key={name} className="skills__skill">
      {img && <img className="skills__skill-image" src={img} alt={name} />}
      {name}
    </div>
  );
};

export default Skill;
