import React from "react";
import Skill from "../Skill/skill";
import "./about.css";

import CustomButton from "../Button.jsx/button";

const skills = [
  { img: "assets/svg/html.svg", name: "HTML" },
  { img: "assets/svg/css.svg", name: "CSS" },
  { img: "assets/svg/javascript.svg", name: "JavaScript" },
  { img: "assets/svg/typescript.svg", name: "Typescript" },
  { img: "assets/svg/react.svg", name: "React" },
  { img: "assets/svg/react-native.svg", name: "React Native" },
  { img: "assets/svg/git.svg", name: "GIT" },
  { img: "assets/svg/nodejs.svg", name: "NodeJS" },
  { img: "assets/svg/graphql.svg", name: "GraphQL" },
  { img: "assets/svg/nextjs.svg", name: "NextJS" },
  { img: "assets/svg/tailwind.svg", name: "Tailwind" },
];

const About = () => {
  return (
    <section id="about" className="about sec-pad">
      <div className="main-container">
        <h2 className="heading heading-sec heading-sec__mb-med">
          <span className="heading-sec__main">About Me</span>
        </h2>
        <div className="about__content">
          <div className="about__content-main">
            <h3 className="about__content-title">Get to know me!</h3>
            <div className="about__content-details">
              <p className="about__content-details-para">
                Hey! It's
                <strong> Veronika Bowersock </strong>
                and I'm a <strong> Frontend Web Developer </strong> located in
                Indiana. I've completed multiple
                <strong> remote </strong>
                projects for a consulting company, creating exceptional{" "}
                <strong>digital products </strong>
                for both business and consumer use.
              </p>
              <p className="about__content-details-para">
                I love learning new things and creating products that make life
                easier and more beautiful. I'm currently working on a few
                projects that I'm excited to share with you soon. Feel free to{" "}
                <strong>contact</strong> me here.
              </p>
            </div>
            <CustomButton label="Contact Me" link="./#contact" size="md" dark />
          </div>
          <div className="about__content-skills">
            <h3 className="about__content-title">My Skills</h3>
            <div className="skills">
              {skills.map((skill) => {
                return <Skill key={skill.name} skill={skill} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
