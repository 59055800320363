import React from "react";
import Footer from "../Footer/footer";
import Contact from "../Contact.jsx/contact";
import Projects from "../Projects/projects";
import About from "../About/about";
import Hero from "../Hero/hero";
import Header from "../Header/header";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
