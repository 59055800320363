import React from "react";
import "./button.css";
import { Link } from "react-router-dom";

const CustomButton = ({ label, link, size = "md", dark = false, ...rest }) => {
  return (
    <Link
      to={link}
      className={`btn btn--${size} btn--theme${dark ? "" : "-inv"}`}
      {...rest}
    >
      {label}
    </Link>
  );
};

export default CustomButton;
