import React from "react";
import Project from "../Project/project";
import "./projects.css";

const Projects = () => {
  return (
    <section id="projects" className="projects sec-pad">
      <div className="main-container">
        <h2 className="heading heading-sec heading-sec__mb-bg">
          <span className="heading-sec__main">Projects</span>
          <span className="heading-sec__sub">
            In my free time, I game, garden, chase my children around, and most
            importantly work on my personal projects. You can find some examples
            below.
          </span>
        </h2>

        <div className="projects__content">
          <Project
            name="News Explorer"
            description="A website that lets you keep up with current news and save
            articles to your profile. Final project for TripleTen bootcamp."
            image="./assets/png/news_mockup.png"
            link="/project/newsexplorer"
          />

          <Project
            name="Eat In Season"
            description="A recipe app for gardeners who want to cook with their home grown produce."
            image="./assets/png/eat_mockup.png"
            link="./project/eatinseason"
          />
          <Project
            name="MyFlock"
            description=" A mobile app for keeping track of a small sheep flock."
            image="./assets/png/sheep_mockup.png"
            link="./project/myflock"
          />
          <Project
            name="MyFlock Landing Page"
            description=" A landing page for myFlock app."
            image="./assets/png/myflock-site-mockup.png"
            link="./project/myflock-site"
          />
        </div>
      </div>
    </section>
  );
};

export default Projects;
