import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="header__content">
        <div className="header__logo-container">
          <div className="header__logo-img-cont">
            <img
              src="../../assets/jpeg/avatar.jpeg"
              alt="Veronika Bowersock Logo Image"
              className="header__logo-img"
            />
          </div>
          <span className="header__logo-sub">Veronika Bowersock</span>
        </div>
        <div className="header__main">
          <ul className="header__links">
            <li className="header__link-wrapper">
              <Link className="header__link" to="/#hero">
                Home
              </Link>
            </li>
            <li className="header__link-wrapper">
              <Link className="header__link" to="/#about">
                About
              </Link>
            </li>
            <li className="header__link-wrapper">
              <Link className="header__link" to="/#projects">
                Projects
              </Link>
            </li>
            <li className="header__link-wrapper">
              <Link className="header__link" to="/#contact">
                Contact
              </Link>
            </li>
          </ul>
          <div
            className="header__main-ham-menu-cont"
            onClick={handleToggleMenu}
          >
            {!isMenuOpen ? (
              <img
                src="../../assets/svg/ham-menu.svg"
                alt="hamburger menu"
                className="header__main-ham-menu"
              />
            ) : (
              <img
                src="../../assets/svg/ham-menu-close.svg"
                alt="hamburger menu close"
                className="header__main-ham-menu-close"
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`header__sm-menu header__sm-menu${
          isMenuOpen ? "--active" : ""
        }`}
      >
        <div className="header__sm-menu-content">
          <ul className="header__sm-menu-links">
            <li className="header__sm-menu-link" onClick={handleToggleMenu}>
              <Link to="/#home">Home</Link>
            </li>

            <li className="header__sm-menu-link" onClick={handleToggleMenu}>
              <Link to="/#about"> About </Link>
            </li>

            <li className="header__sm-menu-link" onClick={handleToggleMenu}>
              <Link to="/#projects"> Projects </Link>
            </li>

            <li className="header__sm-menu-link" onClick={handleToggleMenu}>
              <Link to="/#contact"> Contact </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
