import React from "react";
import "./ProjectDetail.css";
import { useParams } from "react-router-dom";
import projects from "../../projects.json";
import NotFound from "../NotFound/NotFound";
import Footer from "../Footer/footer";
import Header from "../Header/header";
import CustomButton from "../Button.jsx/button";
import logos from "../../logos.json";
import Skill from "../Skill/skill";

const ProjectDetail = () => {
  const { id } = useParams();
  const project = projects.find((project) => project.id === id);
  if (!project) {
    console.error(`No project found with id: ${id}`);
    return <NotFound />;
  }

  const { name, description, overview, image, tools, link, github } = project;
  const getLogoByName = (name) => {
    const logo = logos.find((logo) => logo.name === name);
    return logo ? `../../assets/svg/${logo.logo}` : null;
  };

  return (
    <>
      <Header />
      <section className="project-cs-hero">
        <div className="project-cs-hero__content">
          <h1 className="heading-primary">{name}</h1>
          <div className="project-cs-hero__info">
            <p className="text-primary">{description}</p>
          </div>
          <div className="project-cs-hero__cta">
            <CustomButton
              label="Live Link"
              link={link}
              dark={false}
              size="lg"
              target="_blank"
              rel="noreferrer"
            />
          </div>
        </div>
      </section>
      <section className="project-details">
        <div className="main-container">
          <div className="project-details__content">
            <div className="project-details__showcase-img-cont">
              <img
                src={image}
                alt={`${name} screenshot`}
                className="project-details__showcase-img"
              />
            </div>
            <div className="project-details__content-main">
              <div className="project-details__desc">
                <h3 className="project-details__content-title">
                  Project Overview
                </h3>
                <p className="project-details__desc-para">{overview}</p>
              </div>
              <div className="project-details__tools-used">
                <h3 className="project-details__content-title">Tools Used</h3>
                <div className="skills">
                  {tools.map((tool, index) => (
                    <Skill
                      key={index}
                      skill={{ name: tool, img: getLogoByName(tool) }}
                    />
                  ))}
                </div>
              </div>
              <div className="project-details__links">
                <h3 className="project-details__content-title">See Live</h3>
                <div className="project-details__buttons-container">
                  <CustomButton
                    label="Live Link"
                    link={link}
                    dark
                    size="md"
                    target="_blank"
                    rel="noreferrer"
                  />
                  <CustomButton
                    label="Code Link"
                    link={github.frontend}
                    size="md"
                    target="_blank"
                    rel="noreferrer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ProjectDetail;
