import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/home";

import ProjectDetail from "./components/ProjectDetail/ProjectDetail";

import NotFound from "./components/NotFound/NotFound";
import ScrollToHashElement from "./ScrollToHash";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <>
      <ScrollToHashElement />
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route exact path="/" element={<Home />} />
        <Route path="/project/:id" element={<ProjectDetail />} />
      </Routes>
    </>
  );
}

export default App;
