import React from "react";
import "./NotFound.css";
import CustomButton from "../Button.jsx/button";

const NotFound = () => {
  return (
    <div className="not_found">
      <div className="not_found__content">
        Ooops, this page does not exist yet!
        <br />
        <CustomButton link="/" label="Go Home"></CustomButton>
      </div>
      ;
    </div>
  );
};

export default NotFound;
